<script setup lang="ts">
    import Invoice from '~~/app/invoicing/models/Invoice';

    const { t } = useTranslation();
    const props = defineProps({
  invoice: null,
  size: { default: 'min-w-[100px] px-2 py-1 md:py-2 md:px-3 lg:px-5 font-semibold text-[11px] uppercase md:normal-case md:text-[12px]' },
  customLabel: { default: '' },
  location: { default: 'left' }
});

    const getStatus = () => {
        if (invoiceWasNotSentSuccessfully.value) {
            return 'error';
        }
        return props.invoice?.status;
    };
    const invoiceWasNotSentSuccessfully = computed(() => {
        return props.invoice?.status == 'sent' && !props.invoice?.wasSentSuccessfully;
    });
    const badgeClass = computed(() => {
        return `${props.size} ${useStatusBackgroundColour(getStatus())}`;
    });
    const accentColour = computed(() => {
        return useStatusAccentBackgroundColour(getStatus());
    });
    const label = computed(() => {
        if (props.customLabel) {
            return props.customLabel;
        }

        if (props.invoice?.depositInvoicePercentage === 100) {
            return t(`invoice_status.invoiced`);
        }

        if (props.invoice?.signedPdfUrl) {
            return t(`invoice_status.signed`);
        }

        return t(`invoice_status.${props?.invoice?.status}`);
    });

    const tooltipPercentage = computed(() => {
        if (props.invoice?.documentType !== 'quote') {
            return null;
        }

        return Math.round(props.invoice?.worksiteProgressBillingsPercentage ?? props.invoice?.depositInvoicesPercentage ?? 0);
    });
    const tooltip = computed(() => {
        if (invoiceWasNotSentSuccessfully.value) {
            return t('invoice_status.not_sent_successfully_tooltip');
        }

        return tooltipPercentage.value
            ? t('invoice_status.deposit_invoices_tooltip', {
                  percentage: tooltipPercentage.value,
              })
            : null;
    });
</script>

<template>
    <div
        class="relative z-[2] -mb-1"
        :data-tooltip-location="location"
        :data-tooltip="tooltip"
    >
        <span :class="`whitespace-no-wrap inline-block text-center rounded-full leading-normal ${badgeClass} relative overflow-hidden`">
            <div
                v-if="tooltipPercentage"
                :class="`absolute top-0 bottom-0 left-0 rounded-l-full rounded-r-lg ${accentColour} z-[1] opacity-[0.6]`"
                :style="`width: ${tooltipPercentage}%;`"
            ></div>
            <span class="relative z-[2]">
                {{ label }}
            </span>
        </span>
    </div>
</template>
